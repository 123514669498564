<template>
  <div
    id="bg"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="模型载入中..."
    element-loading-background="rgba(255, 255, 255, 0.6)"
  >
    <div v-if="detail" class="describe_box">
      <div class="describe_content" v-html="detail.describe"></div>
      <div>
        <div @click="goUrl" class="describe_btn" v-if="go_url.length > 3">
          <!-- <img class="describe_img" src="/static/img/vr.png"/> -->
        </div>
        <div
          class="describe_text"
          v-if="isAnimations"
          @click="playPause"
          :class="{ isPlay: isPlay }"
        ></div>
      </div>
    </div>
    <div id="container"></div>

    <div id="img-content">
      {{ logText }}
      <ul class="img-list" v-if="textureArr.length > 1">
        <li
          :data="textureArr"
          v-for="item in textureArr"
          :key="item.key"
          class="img-list-item"
        >
          <img :src="item.path" @click="checkTexOrMesh(item.index)" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
// import { Tween } from "three/examples/jsm/libs/tween.module.js";
import TWEEN from "tween";
// import  TWEEN from "tween.js"
// import * as TWEEN from 'three/examples/jsm/libs/tween.module.js'
// import { Raycaster } from "three";
import aa  from '../assets/33333.json'
var raycaster = new THREE.Raycaster(); //射线
var mouse = new THREE.Vector2(); //鼠标位置
var controls_target = new THREE.Vector3(0, -10, 0); //控制器中心位置 -10
var lightColor, directLight;
var Y_THREEJS = {
  camera: null,
  renderer: null,
  controls: null,
  mesh: null,
  scene: null,
  hdrPath: null,
  node: null,
  mixer: null,
  clock: null,
};

let that = null;
export default {
  data() {
    return {
      detail: null,
      size: 1,
      pmremGenerator: null,
      mouseDown: false,
      mouseX: 0,
      mouseY: 0,
      textureArr: [],
      modelArr: [],
      list: [],
      animationId: null,
      go_url: "",
      baseUrl: "https://hlm.halomu.com",
      logText: "",
      Animations: [],
      isPlay: false,
      isAnimations: false,
      loading: true,
      // baseUrl: "https://guanli.halomu.com",
    };
  },

  mounted() {
    this.init();
    this.animate();
    that = this;
  },

  methods: {
    playPause() {
      this.isPlay = !this.isPlay;
      Y_THREEJS.mixer.clipAction(this.Animations).paused =
        !Y_THREEJS.mixer.clipAction(this.Animations).paused;
    },
    goUrl() {
      location.href = this.go_url;
    },
    init() {
      Y_THREEJS.clock = new THREE.Clock();

      Y_THREEJS.node = document.getElementById("container");
      let scene = new THREE.Scene();
      Y_THREEJS.scene = scene;
      scene.background = new THREE.Color(0x443333);

      Y_THREEJS.camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        2000
      );
      Y_THREEJS.camera.position.z = 150;
      Y_THREEJS.scene.add(Y_THREEJS.camera);
      Y_THREEJS.renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      Y_THREEJS.renderer.setPixelRatio(window.devicePixelRatio);
      Y_THREEJS.renderer.setSize(window.innerWidth, window.innerHeight);
      // Y_THREEJS.renderer.physicallyCorrectLights = true;
      Y_THREEJS.renderer.outputEncoding = THREE.sRGBEncoding;

      Y_THREEJS.node.appendChild(Y_THREEJS.renderer.domElement);

      Y_THREEJS.controls = new OrbitControls(
        Y_THREEJS.camera,
        Y_THREEJS.renderer.domElement
      ); //控制器
      Y_THREEJS.renderer.toneMappingExposure = 1.0;

      Y_THREEJS.controls.target.x = controls_target.x; //设置中心
      Y_THREEJS.controls.target.y = controls_target.y; //设置中心
      Y_THREEJS.controls.target.z = controls_target.z; //设置中心

      // Y_THREEJS.controls.panSpeed = 0.2;
      // Y_THREEJS.controls.zoomSpeed = 0.5;
      Y_THREEJS.controls.minDistance = 50; //设置相机最近只能看哪
      Y_THREEJS.controls.maxDistance = 600; //设置相机最远只能看哪
      //阻尼（惯性开关
      Y_THREEJS.controls.enableDamping = true;
      //阻尼（惯性 速度
      Y_THREEJS.controls.dampingFactor = 0.05;
      // Y_THREEJS.controls.noRotate = false;
      // Y_THREEJS.controls.noPan = false;
      // Y_THREEJS.controls.staticMoving = false;// 静止移动，为 true 则没有惯性
      // Y_THREEJS.controls.dynamicDampingFactor = 0.05;// 阻尼系数 越小 则滑动越大

      //加光
      // lightColor = new THREE.Color("#ffffff");
      // Y_THREEJS.camera.add(new THREE.AmbientLight(lightColor, 5)); //环境光
      // directLight = new THREE.DirectionalLight(lightColor, 2);//直射光
      // directLight.intensity = 0.3;//intensity：强度
      // directLight.position.set(0.5, 0, 100);
      // Y_THREEJS.camera.add(directLight);
      window.addEventListener("resize", this.onWindowResize, false);
      // Y_THREEJS.node.addEventListener("mousemove", this.onDocumentMouseMove, false);
      // Y_THREEJS.node.addEventListener("mousedown", this.onDocumentMouseDown, false);
      // Y_THREEJS.node.addEventListener("mouseup", this.onDocumentMouseUp, false);

      // Y_THREEJS.node.addEventListener("touchmove", this.onDocumentMouseMove, false);
      // Y_THREEJS.node.addEventListener("touchstart", this.onDocumentMouseDown, false);
      // Y_THREEJS.node.addEventListener("touchend", this.onDocumentMouseUp, false);

      Y_THREEJS.node.addEventListener("pointerdown", this.onPointerDown); //监听点击事件

      this.getData();
    },

    getData() {
      let self = this;
      function textFormat(val) {
        // 格式化文字展示文本域格式文字
        if (val) {
          let newString = val.replace(/\n/g, "_@").replace(/\r/g, "_#");
          newString = newString.replace(/_#_@/g, "<br/>"); // IE7-8
          newString = newString.replace(/_@/g, "<br/>"); // IE9、FF、chrome
          newString = newString.replace(/\s/g, "&nbsp;"); // 空格处理
          return newString;
        }
      }
      axios
        .get(
          `${self.baseUrl}/addons/hlm/api.Goods/goodsDetail?model_id=${self.$route.query.modelId}`
        )
        .then((response) => {
          document.title = response.data.data.title;
          response.data.data.list.forEach((item, index) => {
            let describe = textFormat(item.describe);
            this.textureArr.push({
              path: item.image_url,
              hdr: item.hdr_url,
              key: item.id,
              describe: describe,
              index: index,
            });
          });

          self.go_url = response.data.data.list[0].url;
          self.list = response.data.data.list;
          self.detail = this.textureArr[0];
          this.modelArr = response.data.data.list.map((item) => {
            return item.glb_image_url;
          });
          if (response.data.data.list.length > 0) {
            this.modelPath = response.data.data.list[0].glb_image_url;
            //Y_THREEJS.hdrPath = response.data.data.list[0].hdr_url;
          } else {
            this.modelPath = response.data.data.glb_image_url;
            //Y_THREEJS.hdrPath = response.data.data.hdr_url;
          }
          Y_THREEJS.hdrPath = response.data.data.hdr_url;
          this.updateEnvironment(Y_THREEJS.hdrPath);
          console.log(this.modelPath.toString());

          this.loadJson(this.modelPath);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onDocumentMouseMove(event) {
      event.preventDefault();
      event.stopPropagation();
      let flagTouch = event.touches != undefined && event.touches.length == 1;
      let flagMouse = event.which != undefined && event.which == 1;
      let flag = flagTouch || flagMouse;
      if (this.mouseDown && flag) {
        let pos = this.getXY(event);
        var dx = this.mouseX - pos.x;
        var dy = this.mouseY - pos.y;
        if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
          return;
        }
        let currentAngleX = -dx * this.size;
        let currentAngleY = -dy * this.size;
        if (Y_THREEJS.mesh) {
          Y_THREEJS.mesh.rotateOnWorldAxis(
            new THREE.Vector3(1, 0, 0),
            currentAngleY * 0.017453
          );
          Y_THREEJS.mesh.rotateOnWorldAxis(
            new THREE.Vector3(0, 1, 0),
            currentAngleX * 0.017453
          );
        }
        this.mouseX = pos.x;
        this.mouseY = pos.y;
      }
    },

    onDocumentMouseDown(event) {
      event.preventDefault();
      let flagTouch = event.touches != undefined && event.touches.length == 1;
      let flagMouse = event.which != undefined && event.which == 1;
      if (flagTouch || flagMouse) {
        let pos = this.getXY(event);
        this.mouseX = pos.x;
        this.mouseY = pos.y;
        this.mouseDown = true;
      }
    },

    onDocumentMouseUp(event) {
      event.preventDefault();
      let flagTouch = event.touches != undefined && event.touches.length == 1;
      let flagMouse = event.which != undefined && event.which == 1;
      let flag = flagTouch || flagMouse;
      if (this.mouseDown && flag) {
        let pos = this.getXY(event);
        this.mouseX = pos.x;
        this.mouseY = pos.y;
        this.mouseDown = false;
      }
    },

    getXY(event) {
      let windowHalfX = window.innerWidth / 2;
      let windowHalfY = window.innerHeight / 2;
      if (event.touches) {
        var mouseX = (event.touches[0].pageX - windowHalfX) / 2 / 8;
        var mouseY = (event.touches[0].pageY - windowHalfY) / 2 / 8;
        return new THREE.Vector2(mouseX, mouseY);
      } else {
        var mouseX = (event.clientX - windowHalfX) / 2 / 8;
        var mouseY = (event.clientY - windowHalfY) / 2 / 8;
        return new THREE.Vector2(mouseX, mouseY);
      }
    },
    checkTexOrMesh(index) {
      //this.updateEnvironment(this.textureArr[index].hdr);    切换背景，会闪一下，暂时不建议
      this.detail = this.textureArr[index];
      this.go_url = this.list[index].url;
      if (this.modelArr[index] && this.modelArr[index] !== "") {
        Y_THREEJS.scene.remove(Y_THREEJS.mesh);
        this.removeObj(Y_THREEJS.mesh);
        this.loadJson(this.modelArr[index]);
      } else {
        this.updateTexture(this.textureArr[index].path);
      }
    },
    //加载模型 hdr
    loadJson(modelPath) {
      let that = this;
      that.loading = true;
        console.log('aa',aa);
      if (modelPath.indexOf(".json") != -1) {
        var loader = new THREE.ObjectLoader();
        loader.setCrossOrigin("Anonymous");
        loader
          .load(
            modelPath,
            // 'https://hlmqn.halomu.net/uploads/20240914/71bea4a46bb81391ee7264d9b34287bf.json',
            // aa,
            // "../../static/44444.json",
            function (res) {
              console.log(res);
                console.log("模型加载完成", res);
            Y_THREEJS.mesh = res;
            Y_THREEJS.scene.add(Y_THREEJS.mesh);
            that.loading = false;
            try {
              lightColor.visible = false;
              directLight.visible = false;
            } catch (e) {
              e;
            }
            that.isAnimations = false;
            Y_THREEJS.mesh.traverse((res) => {
              if (res.animations.length) {
                console.log(res);
              }

              if (res.animations.length > 0) {
                that.isAnimations = true;
                that.isPlay = false;
                Y_THREEJS.mixer = new THREE.AnimationMixer(res);
                that.Animations = res.animations[0];
              
                
                console.log(res.animations[0].name);
                // console.log(Y_THREEJS.mixer.clipAction(that.Animations));

                const action = Y_THREEJS.mixer.clipAction(that.Animations);
                action.play();
                console.log("动画开始播放", action);
                action.paused = true;
                // Y_THREEJS.mixer.clipAction(this.Animations).paused = true;
              }
            });
            },
            function (xhr) {
              console.log(xhr);
              
            },
            function (error) {
              console.log(error);
            }
          )
          // .then((res) => {
          //   console.log("模型加载完成", res);
          //   Y_THREEJS.mesh = res;
          //   Y_THREEJS.scene.add(Y_THREEJS.mesh);
          //   that.loading = false;
          //   try {
          //     lightColor.visible = false;
          //     directLight.visible = false;
          //   } catch (e) {
          //     e;
          //   }
          //   that.isAnimations = false;
          //   Y_THREEJS.mesh.traverse((res) => {
          //     if (res.animations.length) {
          //       console.log(res);
          //     }

          //     if (res.animations.length > 0) {
          //       that.isAnimations = true;
          //       that.isPlay = false;
          //       Y_THREEJS.mixer = new THREE.AnimationMixer(res);
          //       that.Animations = res.animations[0];
              
                
          //       console.log(res.animations[0].name);
          //       // console.log(Y_THREEJS.mixer.clipAction(that.Animations));

          //       const action = Y_THREEJS.mixer.clipAction(that.Animations);
          //       action.play();
          //       console.log("动画开始播放", action);
          //       action.paused = true;
          //       // Y_THREEJS.mixer.clipAction(this.Animations).paused = true;
          //     }
          //   });
          // })
      } else {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("../assets/wasm/");
        dracoLoader.setDecoderConfig({
          type: "js",
        });
        dracoLoader.preload();
        loader.setDRACOLoader(dracoLoader);
        loader.load(modelPath, (gltf) => {
          Y_THREEJS.mesh = gltf.scene || gltf.scenes[0];
          that.loading = false;
          //const box = new THREE.BoxHelper( Y_THREEJS.mesh, 0xffff00 );
          // box.geometry.computeBoundingSphere();
          // let scale = box.geometry.boundingSphere.radius / 1024
          //  Y_THREEJS.mesh.scale.set(scale, scale, scale);

          Y_THREEJS.mesh.traverse((node) => {
            if (!node.isMesh) return;
            //node.material.roughness = 0.3;  光滑反射

            node.material.transparent = true;
            node.material.needsUpdate = true;
          });
          //	Y_THREEJS.mesh.traverse((node) => {
          //	if (!node.isMesh) return;
          //	node.scale.set(1.0, 1.0, 1.0);
          //	node.rotateZ((30 * Math.PI) / 180);
          //	});
          Y_THREEJS.scene.add(Y_THREEJS.mesh);
          dracoLoader.dispose();
        });
      }
    },

    //修改材质
    updateTexture(path) {
      let self = this;
      let textureLoader = new THREE.TextureLoader();
      textureLoader.load(path, (texture) => {
        self.mesh.traverse((node) => {
          if (!node.isMesh) return;
          node.material.needsUpdate = true;
          node.material.map = texture;
        });
      });
    },

    //加载背景
    updateEnvironment(path) {
      let _this = this;
      console.log(path);
      if (path.indexOf(".jpg") != -1) {
        // new THREE.RGBELoader()
        // .setDataType( THREE.UnsignedByteType )
        // .load( path, function ( texture ) {
        // // model

        // const pmremGenerator = new THREE.PMREMGenerator( _Y_THREEJS.renderer );
        // pmremGenerator.compileEquirectangularShader();
        // const envMap = pmremGenerator.fromEquirectangular( texture ).texture;

        // _Y_THREEJS.scene.background = envMap;
        // //_Y_THREEJS.scene.environment = envMap;

        // texture.dispose();
        // pmremGenerator.dispose();

        // } );

        Y_THREEJS.scene.background = new THREE.TextureLoader().load(path);
        Y_THREEJS.scene.background.mapping =
          THREE.EquirectangularReflectionMapping;
      } else {
        this.pmremGenerator = new THREE.PMREMGenerator(Y_THREEJS.renderer);
        this.pmremGenerator.compileEquirectangularShader();
        Y_THREEJS.scene.background = new THREE.TextureLoader().load(path);
      }

      //let Texture = new THREE.TextureLoader().load(  path);
      //Texture.mapping = THREE.EquirectangularReflectionMapping;
      //Y_THREEJS.scene.environment = Texture
      // Y_THREEJS.scene.environment.mapping === THREE.EquirectangularReflectionMapping
      // Y_THREEJS.scene.environment.type == "Equirectangular"

      console.log(Y_THREEJS.scene);
      // this.getCubeMapTexture(path).then(({
      //                                        envMap
      //                                    }) => {
      //     Y_THREEJS.scene.environment = envMap;
      //     Y_THREEJS.scene.background = envMap;
      // });
    },

    getCubeMapTexture(path) {
      return new Promise((resolve, reject) => {
        new RGBELoader().setDataType(THREE.UnsignedByteType).load(
          path,
          (texture) => {
            const envMap =
              this.pmremGenerator.fromEquirectangular(texture).texture;
            this.pmremGenerator.dispose();

            resolve({
              envMap,
            });
          },
          undefined,
          reject
        );
      });
    },

    clear() {
      if (Y_THREEJS.mesh) {
        Y_THREEJS.mesh.material.map.dispose();
        Y_THREEJS.mesh.material.dispose();
        Y_THREEJS.mesh.geometry.dispose();
        Y_THREEJS.mesh = null;
      }
      Y_THREEJS.renderer.dispose();
      Y_THREEJS.camera = null;
      Y_THREEJS.renderer = null;
    },

    //清空资源
    removeObj(obj) {
      let arr = obj.children.filter((x) => x);
      arr.forEach((item) => {
        if (item.children.length) {
          this.removeObj(item);
        } else {
          this.clearCache(item);
          item.clear();
        }
      });
      obj.clear();
      arr = null;
    },

    clearCache(item) {
      if (item.geometry && item.material) {
        item.geometry.dispose();
        item.material.dispose();
      } else if (item.environment) {
        item.environment.dispose();
      }
    },

    animate() {
      // console.log(Y_THREEJS.mixer);
      if (Y_THREEJS.mixer) Y_THREEJS.mixer.update(Y_THREEJS.clock.getDelta());
      TWEEN.update( );
    
      // if (TWEEN.update() !== null) {
      //     console.log('有动画在运行',Y_THREEJS.camera.position);
      // } else {
      //     console.log('没有动画在运行');
      // }
      Y_THREEJS.controls.update();
      Y_THREEJS.renderer.render(Y_THREEJS.scene, Y_THREEJS.camera);
      // return
      this.animationId = requestAnimationFrame(this.animate);
    },

    onWindowResize() {
      Y_THREEJS.camera.aspect = window.innerWidth / window.innerHeight;
      Y_THREEJS.camera.updateProjectionMatrix();
      Y_THREEJS.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    changeTexture(texturePath) {
      this.updateTexture(texturePath);
    },
    //点击执行
    onPointerDown(event) {
      let time = new Date().valueOf();
      if (this.event_time && time - this.event_time < 500) {
        let x = Math.abs(this.event_x - event.clientX);
        let y = Math.abs(this.event_y - event.clientY);
        if (x + y < 10) {
          new TWEEN.Tween(Y_THREEJS.controls.target)
            .to(controls_target, 500)
            .start(); //双击屏幕=》设置中心点返回=》1000现在的位置回到初始位置的时间 1000就是1秒
          new TWEEN.Tween(Y_THREEJS.camera.position)
            .to({ x: 0, y: 0, z: 150 }, 500)
            .start(); //双击屏幕=》设置相机返回=》1000现在的位置回到初始位置的时间 1000就是1秒
        }
      }
      this.event_x = event.clientX;
      this.event_y = event.clientY;
      this.event_time = time;

      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, Y_THREEJS.camera);
      const intersects = raycaster.intersectObjects(
        Y_THREEJS.scene.children,
        true
      );
      if (intersects.length > 0) {
        console.log("当前点击的模型名称：", intersects[0].object.name); //当前点击的模型，显示详情
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
    this.removeObj(Y_THREEJS.scene);
    Y_THREEJS.renderer.renderLists.dispose();
    Y_THREEJS.renderer.dispose();
    Y_THREEJS.renderer.forceContextLoss();
    Y_THREEJS.renderer.domElement = null;
    Y_THREEJS.renderer.content = null;
    Y_THREEJS.renderer = null;
    cancelAnimationFrame(this.animationId);
    THREE.Cache.clear();
    if (Y_THREEJS.controls) {
      Y_THREEJS.controls.dispose();
      Y_THREEJS.controls = null;
      Y_THREEJS.node.removeEventListener(
        "mousemove",
        this.onDocumentMouseMove,
        false
      );
      Y_THREEJS.node.removeEventListener(
        "mousedown",
        this.onDocumentMouseDown,
        false
      );
      Y_THREEJS.node.removeEventListener(
        "mouseup",
        this.onDocumentMouseUp,
        false
      );

      Y_THREEJS.node.removeEventListener(
        "touchmove",
        this.onDocumentMouseMove,
        false
      );
      Y_THREEJS.node.removeEventListener(
        "touchstart",
        this.onDocumentMouseDown,
        false
      );
      Y_THREEJS.node.removeEventListener(
        "touchend",
        this.onDocumentMouseUp,
        false
      );
    }
  },
};
</script>

<style>
div {
  box-sizing: border-box;
}
.describe_box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
  padding: 12px;
  word-break: break-all;
  display: flex;
  justify-content: space-between;
}
.describe_content {
  width: 80%;
}
.describe_btn {
  width: 40px;
  height: 40px;
  background-image: url("../assets/img/quanjing.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 10px;
}
.describe_text {
  width: 40px;
  height: 40px;
  background-image: url("../assets/img/bofang.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.isPlay {
  background-image: url("../assets/img/zanting.png");
}
.describe_img {
  width: 100%;
}

#container {
  height: 100%;
  width: 100%;
}

#bg {
  height: 100%;
  width: 100%;
  position: absolute;
}

#img-content {
  width: 100%;
  overflow-x: auto;
  position: absolute;
  bottom: 10%;
}

.img-list {
  white-space: nowrap;
  margin: 0;
  padding: 0 0 0 20px;
}

.img-list .img-list-item {
  width: 100px;
  height: 100px;
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
}

.img-list .img-list-item img {
  width: 100%;
  height: 100%;
}
.el-loading-spinner i {
  color: rgb(89, 188, 160, 0.7) !important;
  font-size: 30px;
}
.el-loading-text {
  color: rgb(89, 188, 160, 0.7) !important;
}
</style>
